ion-content {
  --background: var(--background-primary);
  user-select: text;
  -webkit-user-select: text;

  font-weight: var(--accessability-bold-text);
  /* margin-bottom: 50px !important;
  padding-bottom: 50px !important; */
}

ion-content::part(scroll) {
  /* padding-bottom: 64px; */
}

ion-button {
  text-decoration: var(--accessability-underline-links);
}

img {
  /** 
   * Bug fix for:
   * https://github.com/ionic-team/ionic/issues/18734
   */
  min-height: 1px !important;
}

ion-header {
  background-color: var(--nav-bar-color);
}

ion-toolbar {
  max-width: 1034px;
  margin-left: auto;
  margin-right: auto;
}

.globalContentMargin {
  /* margin-top: 16px; */
  /* margin-bottom: 20px; */
  /* margin-left: 16px; */
  /* margin-right: 16px; */
}

.globalContentGridMargin {
}

.globalContentOnlyWideMargin {
}

.appColorOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--filter-color);
  z-index: 1000;
  pointer-events: none;
}

/* web */
@media (min-width: 1065px) {
  .globalContentMargin {
    max-width: 1004px;
    margin-left: auto;
    margin-right: auto;
  }
  .globalContentGridMargin {
    max-width: 1004px;
    margin-left: auto;
    margin-right: auto;
  }
  .globalContentOnlyWideMargin {
    max-width: 1004px;
    margin-left: auto;
    margin-right: auto;
  }
}

/* tablet landscape */
@media (min-width: 921px) and (max-width: 1064px) {
  .globalContentMargin {
    margin-left: 30px;
    margin-right: 30px;
  }
  .globalContentGridMargin {
    margin-left: 30px;
    margin-right: 30px;
  }
  .globalContentOnlyWideMargin {
    margin-left: 0px;
    margin-right: 0px;
  }
}

/* tablet portrait */
@media (min-width: 421px) and (max-width: 920px) {
  .globalContentMargin {
    margin-left: 24px;
    margin-right: 24px;
  }
  .globalContentGridMargin {
    margin-left: 24px;
    margin-right: 24px;
  }
  .globalContentOnlyWideMargin {
    margin-left: 0px;
    margin-right: 0px;
  }
}

/* phone */
@media (max-width: 420px) {
  .globalContentMargin {
    margin-left: 16px;
    margin-right: 16px;
  }
  .globalContentGridMargin {
    margin-left: 10px;
    margin-right: 10px;
  }
  .globalContentOnlyWideMargin {
    margin-left: 0px;
    margin-right: 0px;
  }
}

ion-toolbar {
  --border-color: transparent;
}

ion-title {
  --color: #fff;
}

ion-button {
  text-transform: none;
  --background: transparent;
  font-size: 16px;
  font-weight: 500;
  --background: transparent;
  --background-activated: transparent;
  --background-focused: transparent;
  --background-hover: transparent;
  letter-spacing: -0.2px;
}

.customBackButtonIcon {
  margin-right: 0px !important;
}

.ion-input-select {
  height: 32px;
  background-color: #ffffff;
  font-size: 1.1em;
  font-size: 20px !important;
  font-weight: 500;
  color: #333333;
  vertical-align: middle;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

ion-select {
  /* width: 170px; */
  /* margin: 0px !important; */
  /* color: #007BFF; */
  /* font-size: 20px; */
  padding-left: 0px !important;
}

.popover-viewport.sc-ion-popover-ios {
  overflow: auto;
}

/* Bug fix for popover not displaying in Chrome 114 (can be fixed by upgrading to ionic 6.5.2) */
ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}

ion-select::part(text) {
  color: var(--text-90);
  font-size: 15px;
  font-weight: 600;
  padding-left: 12px;
}

ion-select::part(placeholder) {
  color: var(--cta);
  font-size: 15px;
  font-weight: 600;
  padding-left: 12px;
  opacity: 1;
}

/* Set the icon color and opacity */
ion-select::part(icon) {
  color: var(--cta);
  opacity: 1;
  padding-right: 12px;
}

.linkText {
  text-decoration: underline;
  color: var(--cta);
  cursor: pointer;
}

.navBar {
  --background: var(--nav-bar-color);
  background-color: var(--nav-bar-color);
}

@supports (top: env(safe-area-inset-top)) {
  .navBar {
    padding-top: calc(env(safe-area-inset-top)) !important;
  }
}

.noPadding {
  padding: 0px !important;
}

.headerTabsVisible {
  top: 0;
  transition: top 0.3s ease-out;
}

.headerTabsHidden {
  top: -100px;
  transition: top 0.3s ease-out;
}

.filledButton {
  /* height: 44px; */
  --padding-start: 12px;
  --padding-end: 12px;
  min-width: 124px;
  --border-radius: 8px;
  --background: var(--cta) !important;
  font-weight: bold;
  /* --box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.2); */
}

.greyFilledButton {
  /* height: 44px; */
  --padding-start: 12px;
  --padding-end: 12px;
  --padding-top: 15px;
  --padding-bottom: 15px;
  min-width: 124px;
  --border-radius: 8px;
  color: var(--cta);
  --background: var(--grey-20) !important;
  font-weight: 600;
  /* --box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.2); */
}

.clearButton {
  /* height: 44px; */
  --background: transparent;
  --padding-start: 0px;
  --padding-end: 0px;
  --color: var(--cta);
  font-weight: 500;
  --box-shadow: none;
}

.outlineButton {
  /* height: 44px; */
  --background: transparent;
  --padding-start: 34px;
  --padding-end: 34px;
  --border-radius: 8px !important;
  --border-width: 1px;
  --border-style: solid;
  --border-color: var(--cta);
  color: var(--cta);
  --box-shadow: none;
}

.filledButtonWarning {
  /* height: 44px; */
  --padding-start: 34px;
  --padding-end: 34px;
  --border-radius: 8px !important;
  /* --box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.2); */
  --background: var(--cta-warning) !important;
  color: var(--background-secondary);
}

.clearButtonWarning {
  /* height: 44px; */
  --background: transparent;
  --padding-start: 0px;
  --padding-end: 0px;
  color: var(--cta-warning);
  --box-shadow: none;
}

.outlineButtonWarning {
  /* height: 44px; */
  --background: transparent;
  --padding-start: 34px;
  --padding-end: 34px;
  --border-radius: 8px !important;
  --border-width: 1px;
  --border-style: solid;
  --box-shadow: none;
  --border-color: var(--cta-warning);
  color: var(--cta-warning);
}

.toolbarButton {
  color: var(--white);
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: 600;
  --background-hover: transparent;
}

.endAligned {
  align-self: flex-end;
}

.menuButton {
  color: var(--white);
}

.headerButtonText {
  color: #ffffff !important;
  height: 32px !important;
}

.headerButtons {
  color: #ffffff !important;
  max-height: 44px !important;
  width: 44px !important;
  --padding-top: 0px !important;
  --padding-bottom: 0px !important;
  --padding-start: 0px !important;
  --padding-end: 0px !important;
}

.headerNotificationContainer {
  width: 60px !important;
  padding-left: 4px;
  padding-right: 4px;
  /* background-color: purple; */
}

.headerNotificationIcon {
  height: 20px !important;
}

.headerNotificationCount {
  position: absolute;
  top: 0;
  right: 0;
  --background: var(--cta-warning);
  --color: var(--white);
  font-size: 11px;
  font-weight: bold;
}

.icon-20 {
  width: 20px !important;
  height: 20px !important;
}

.icon-32 {
  width: 32px !important;
  height: 32px !important;
}

.icon-36 {
  width: 36px !important;
  height: 36px !important;
}

.icon-44 {
  width: 44px !important;
  height: 44px !important;
}

.icon-padding {
  padding: 12px;
}

.icon-toggle {
  width: 53px !important;
  height: 31px !important;
}

#accountSwitcherModal {
  --height: auto;
  --width: 100%;
}

.accountSwitcherContent {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  background-color: var(--background-primary);
  max-height: 80vh;
  overflow-y: auto;
}

.accountSwitcherUser {
  margin-bottom: 8px;
  background-color: var(--background-secondary);
  border-top: solid 1px var(--border-light);
  border-bottom: solid 1px var(--border-light);
}

.accountSwitcherRow {
  flex: 1;
  display: flex;
  align-items: center;
  border-bottom: solid 1px var(--border);
  margin-left: 16px;
}

.accountSwitcherRow:active {
  background-color: var(--background-tertiary);
}

.accountSwitcherTrustRow {
  flex: 1;
  display: flex;
  align-items: center;
  border-bottom: solid 1px var(--border);
  margin-left: 32px;
}

.accountSwitcherTrustRow:last-child {
  border-bottom: 0;
}

.accountSwitcherTrustRow:active {
  background-color: var(--background-tertiary);
}

.accountPageTrustRow {
  margin-left: 4px;
}

.accountSwitcherEmail {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-right: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: calc(16px + var(--font-size-change));
  font-weight: 400;
  color: var(--text-90);
}

.accountSwitcherEmailiCPG {
  font-weight: 600;
}

.accountSwitcherTrust {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-right: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: calc(16px + var(--font-size-change));
  font-weight: 600;
  color: var(--text-90);
}

.accountSwitcherSubtitle {
  font-size: calc(13px + var(--font-size-change));
  margin-top: 4px;
}

.accountSwitcherTickIcon {
  margin-right: 16px;
}

.accountSwitcherAddAccount {
  display: flex;
  align-items: center;
  border-bottom: solid 1px var(--border);
  color: var(--cta);
  font-size: calc(16px + var(--font-size-change));
  font-weight: 600;
  padding: 5px 6px;
}

.accountSwitcherAddAccount:active {
  background-color: var(--background-tertiary);
}

#accountModal::part(content) {
  width: 100vw !important;
  height: 100vh !important;
  margin-top: 0 !important;
  border-radius: 0px !important;
}

#onboardingModal::part(content) {
  width: 100vw !important;
  height: 100vh !important;
  margin-top: 0 !important;
  border-radius: 0px !important;
}

#drugPopover::part(content) {
  width: 100vw !important;
  height: 100vh !important;
  margin-top: 0 !important;
  border-radius: 0px !important;
}

#searchModal::part(content) {
  width: 100vw !important;
  height: 100vh !important;
  margin-top: 0 !important;
  border-radius: 0px !important;
}

#ssoModal::part(content) {
  width: 100vw !important;
  height: 100vh !important;
  margin-top: 0 !important;
  border-radius: 0px !important;
}

#imageModal::part(content) {
  height: 100%;
  width: 100%;
}

.imageModalWrapper {
  height: 100% !important;
  width: 100% !important;
  background-color: black;
}

#imageModalContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.imageModalOuterDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100% !important;
}

#imageModalWrapperClass {
  flex: 1;
  height: 90% !important;
}

.imageModalThumbnails {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  background-color: black;
}

.imageModalThumbnail {
  height: 44px;
  margin-left: 8px;
  margin-right: 8px;
  padding: 2px;
  border: solid 2px black;
  border-radius: 5px;
}

.imageModalThumbnailActive {
  border: solid 2px var(--grey-40);
}

.referenceCardViewGuideline {
  background-color: #f7faf8;
  left: 0;
  right: 0;
  padding-bottom: 20px;
  text-align: center;
  color: var(--cta);
  font-size: 16px;
  font-weight: 700;
  padding-top: 16px;
  cursor: pointer;
}

.emptyPageContainer {
  padding: 10px;
  height: 100%;
  background-color: var(--background-secondary);
}

.emptyPageText {
  font-weight: 600;
  font-size: 17px;
  color: var(--text-70);
}

.webAppBanner {
  display: flex;
  flex-direction: column;
  margin: 24px 16px;
  padding: 16px 12px;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px rgba(0, 0, 0, 0.05);
  background-color: #feebc8;
  color: #4d1605;
}

.webAppBannerTitle {
  font-size: 17px;
  font-weight: 800;
  margin-bottom: 8px;
}

.webAppBannerLink {
  display: contents;
}

.webAppBannerButton {
  align-self: flex-end;
  font-weight: 600;
}

.tabBarIconTop {
  height: 30px !important;
  width: 30px !important;
  object-fit: cover;
  margin-bottom: -4px;
}

.tabBarIconStart {
  height: 32px !important;
  width: 32px !important;
  margin-top: 2px;
  margin-right: 3px;
  object-fit: cover;
}

.tabBarLabelTop {
  font-size: 10px !important;
  font-weight: 500;
}

.tabBarLabelStart {
  font-size: 13px;
  font-weight: 500;
}

ion-tab-bar {
  background-color: var(--tab-bar-fill);
  /* position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  transition: bottom 0.3s ease-out; */
}

ion-tab-bar.tabBarHidden {
  bottom: -100px;
  transition: bottom 0.3s ease-out;
}

ion-tab-button {
  background-color: var(--tab-bar-fill);
  border-top-style: solid;
  border-top-width: 2px;
  border-top-color: var(--tab-bar-separator);
}

ion-tab-button ion-label {
  height: 16px;
}

ion-tab-button.tab-selected {
  border-top-style: solid;
  border-top-width: 2px;
  border-top-color: var(--cta);
  background: rgb(0, 122, 255);
  background: linear-gradient(
    180deg,
    rgba(0, 122, 255, 0.10395408163265307) 0%,
    rgba(0, 122, 255, 0) 75%,
    rgba(0, 212, 255, 0) 100%
  );
}

.modalContent {
  overflow: hidden;
}

#cfrDisclaimerPopover .modal-wrapper {
  width: 325px;
  height: 310px;
  border-radius: 8px !important;
}

#cfrDisclaimerPopover ion-content {
  overflow: hidden !important;
  overflow-y: hidden !important;
  border-radius: 8px !important;
}

.cfrDashboardHeaderDivDisclaimer {
  background-color: var(--cfr-yellow);
  display: flex;
  flex: 1;
  align-items: center;
  font-size: 20px;
  padding: 10px;
}

.cfrDisclaimerHeaderText {
  font-weight: 700;
  color: var(--grey-90);
  margin-left: 10px;
}

.cfrDisclaimerPlusText {
  font-weight: 500;
  color: #007e6a;
  margin-left: 6px;
}

.cfrDisclaimerLogo {
  width: 30px;
  height: 30px;
}

.cfrDisclaimerTitle {
  font-weight: 500;
  display: block;
  text-align: center;
  font-size: 19px;
  margin-top: 15px;
  margin-bottom: 10px;
}

.cfrDisclaimerText {
  display: block;
  text-align: center;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1;
  margin-bottom: 20px;
}

.cfrDisclaimerButton {
  display: flex;
  justify-content: center;
}

.sliver {
  min-width: 5px !important;
  width: 5px !important;
  height: auto !important;
}

/* category colours */
.generalGuidance {
  background-color: var(--category-general-guidance) !important;
}

.resuscitation {
  background-color: var(--category-resuscitation) !important;
}

.medical {
  background-color: var(--category-medical) !important;
}

.maternityCare {
  background-color: var(--category-maternity-care) !important;
}

.trauma {
  background-color: var(--category-trauma) !important;
}

.meds {
  background-color: var(--category-meds) !important;
}

.specialSituations {
  background-color: var(--category-special-situations) !important;
}

.pageForAge {
  background-color: var(--category-page-for-age) !important;
}

.algorithms {
  background-color: var(--category-algorithms) !important;
}

.clinicalNotice {
  background-color: #941638 !important;
}

.essentials {
  background-color: #3fae49 !important;
}

.sectionGuidelines {
  background-color: #005e55 !important;
}

.significantChangeJRCALC {
  background-color: #e8ecef !important;
}

.sharedProcedure {
  background-color: #016047 !important;
}

.BRISTOW {
  background-color: #419cd6 !important;
  color: #fff;
}

.EEAST {
  background-color: #72a245 !important;
  color: #fff;
}

.EMAS {
  background-color: #009639 !important;
  color: #fff;
}

.IOM {
  background-color: #18a6b9 !important;
  color: #fff;
}

.JAS {
  background-color: #3233a6 !important;
  color: #fff;
}

.LAS {
  background-color: #03a499 !important;
  color: #fff;
}

.NEAS {
  background-color: #8894b5 !important;
}

.NEOM {
  background-color: #13100d !important;
  color: #fff;
}

.NIAS {
  background-color: #77a02f !important;
  color: #fff;
}

.NWAS {
  background-color: #6f99be !important;
  color: #fff;
}

.ROLE1 {
  background-color: #20418b !important;
  color: #fff;
}

.SAS {
  background-color: #8695b1 !important;
  color: #fff;
}

.SCAS {
  background-color: #b78e08 !important;
}

.SECAMB {
  background-color: #22a2bb !important;
  color: #fff;
}

.SJA {
  background-color: #06a863 !important;
  color: #fff;
}

.SJARS {
  background-color: #06a863 !important;
  color: #fff;
}

.SWAST {
  background-color: #5897e5 !important;
  color: #fff;
}

.TST {
  background-color: #7d2783 !important;
  color: #fff;
}

.WAS {
  background-color: #8095ba !important;
  color: #fff;
}

.WMAS {
  background-color: #39aa39 !important;
  color: #fff;
}
.YAS {
  background-color: #259ed0 !important;
  color: #fff;
}

.acknowledged {
  background-color: var(--background-tertiary) !important;
  color: var(--text-90) !important;
  border-top: solid 1px var(--border);
  border-bottom: solid 1px var(--border);
}

.unacknowledged {
  background-color: #941638 !important;
  color: #ffffff !important;
}

.titleMatches {
  background-color: #368d6d !important;
  color: #ffffff !important;
}

.defaultColor {
  background-color: var(--category-default) !important;
  border-top: solid rgba(255, 255, 255, 0.3) 1px;
  /* box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.5); */
}
