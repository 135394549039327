:root {
  --nav-bar-color: var(--icpg-blue-50);
}

body {
  margin: 0px;
}

.loadingScreen {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background-color: var(--icpg-blue-50);
}

.loadingScreenText {
  color: var(--white);
  font-family: Inter, Helvetica;
  font-size: 20px;
  font-weight: 600;
  margin-right: 20px;
}

.loadingScreenSpinner {
  --color: var(--white);
  width: 32px;
  height: 32px;
}
